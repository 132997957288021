import { Button, Dropdown, Tooltip } from "antd";
import { LockOutlined } from "@ant-design/icons";
import OutlinedButton from "./OutlinedButton";

const DropdownButtonApplication = ({
  children,
  handleStartWbsoApplication,
}) => {
  return (
    <Dropdown
      menu={{
        items: [
          {
            key: "nl",
            label: "Netherlands",
            children: [
              {
                key: "wbso",
                label: (
                  <a onClick={handleStartWbsoApplication}>WBSO Application</a>
                ),
              },
              {
                key: "innovation_credit",
                label: (
                  <Tooltip title="A comprehensive written application is mandatory. Applicants must submit detailed business and project plans, including financial projections and risk assessments.">
                    <a
                      onClick={() => console.log("innovation_credit")}
                      style={{ pointerEvents: "none" }}
                    >
                      Innovation Credit (Innovatiekrediet)
                      <span style={{ marginLeft: "5px" }}>
                        <LockOutlined />
                      </span>
                    </a>
                  </Tooltip>
                ),
              },
              {
                key: "mit_scheme",
                label: (
                  <Tooltip title="A formal written application is required. The process involves submitting proposals for feasibility studies, R&D collaborations, or knowledge vouchers, depending on the specific component of the scheme.">
                    <a
                      onClick={() => console.log("mit_scheme")}
                      style={{ pointerEvents: "none" }}
                    >
                      MIT Scheme (MKB-Innovatiestimulering Regio en Topsectoren)
                      <span style={{ marginLeft: "5px" }}>
                        <LockOutlined />
                      </span>
                    </a>
                  </Tooltip>
                ),
              },
              {
                key: "eurostars_programme",
                label: (
                  <Tooltip title="A detailed written application is necessary. This includes a comprehensive project proposal developed in collaboration with international partners, outlining the innovation’s objectives, methodology, and expected outcomes.">
                    <a
                      onClick={() => console.log("eurostars_programme")}
                      style={{ pointerEvents: "none" }}
                    >
                      Eurostars Programme
                      <span style={{ marginLeft: "5px" }}>
                        <LockOutlined />
                      </span>
                    </a>
                  </Tooltip>
                ),
              },
            ],
          },
          {
            key: "germany",
            label: "Germany",
            children: [
              {
                key: "forschungszulage",
                label: (
                  <Tooltip title="You are not eligible for this application">
                    <a
                      onClick={() => console.log("forschungszulage")}
                      style={{ pointerEvents: "none" }}
                    >
                      Forschungszulage
                      <span style={{ marginLeft: "5px" }}>
                        <LockOutlined />
                      </span>
                    </a>
                  </Tooltip>
                ),
              },
            ],
          },
          {
            key: "france",
            label: "France",
            children: [
              {
                key: "cir",
                label: (
                  <Tooltip title="You are not eligible for this application">
                    <a
                      onClick={() => console.log("cir")}
                      style={{ pointerEvents: "none" }}
                    >
                      CIR (Crédit d’Impôt Recherche)
                      <span style={{ marginLeft: "5px" }}>
                        <LockOutlined />
                      </span>
                    </a>
                  </Tooltip>
                ),
              },
            ],
          },
          {
            key: "uk",
            label: "United Kingdom",
            children: [
              {
                key: "rd_tax_relief",
                label: (
                  <Tooltip title="You are not eligible for this application">
                    <a
                      onClick={() => console.log("rd_tax_relief")}
                      style={{ pointerEvents: "none" }}
                    >
                      R&D Tax Relief
                      <span style={{ marginLeft: "5px" }}>
                        <LockOutlined />
                      </span>
                    </a>
                  </Tooltip>
                ),
              },
            ],
          },
          {
            key: "eu",
            label: "European Union",
            children: [
              {
                key: "horizon_europe",
                label: (
                  <Tooltip title="You are not eligible for this application">
                    <a
                      onClick={() => console.log("horizon_europe")}
                      style={{ pointerEvents: "none" }}
                    >
                      Horizon Europe
                      <span style={{ marginLeft: "5px" }}>
                        <LockOutlined />
                      </span>
                    </a>
                  </Tooltip>
                ),
              },
              {
                key: "innovation_fund",
                label: (
                  <Tooltip title="You are not eligible for this application">
                    <a
                      onClick={() => console.log("innovation_fund")}
                      style={{ pointerEvents: "none" }}
                    >
                      Innovation Fund
                      <span style={{ marginLeft: "5px" }}>
                        <LockOutlined />
                      </span>
                    </a>
                  </Tooltip>
                ),
              },
            ],
          },
        ],
      }}
      trigger={["click"]}
    >
      {children ? (
        children
      ) : (
        <OutlinedButton>Start New Application</OutlinedButton>
      )}
    </Dropdown>
  );
};

export default DropdownButtonApplication;
