import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  GoogleOutlined,
  LockOutlined,
} from "@ant-design/icons";
import LayoutLoginArea from "../components/layouts/layoutAuthenticatedUser";

import {
  Button,
  Dropdown,
  Menu,
  Tag,
  Tooltip,
  message,
  Modal,
  Upload,
  Form,
} from "antd";

import useWbsoApplications from "../data/wbso/useWbsoApplications";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import { useState } from "react";
import { ApiCall } from "../services";
import CustomTable from "../components/table";
import { useAuth } from "../components/contexts/firebaseContext";
import { ALL_PAGES } from "../components/utils/const";
import DropdownButtonApplication from "../components/buttons/DropDownButtonApplication";

export const COLUMNS = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Type",
    // dataIndex: "created_at",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Application Hours",
    dataIndex: "hours",
    key: "hours",
  },
  {
    title: "Application Value",
    dataIndex: "value",
    key: "value",
  },
  {
    title: "",
    dataIndex: "action",
    key: "action",
    width: 250,
  },
];

const DashboardView = () => {
  const router = useRouter();

  const [messageApi, contextHolder] = message.useMessage();
  const { authUser } = useAuth();

  const { data, isLoading, refetch } = useWbsoApplications();

  const [isDeletingRowId, setIsDeletingRowId] = useState(null);

  // Modal state
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentUuid, setCurrentUuid] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [form] = Form.useForm();

  const handleDelete = async (id) => {
    try {
      setIsDeletingRowId(id);

      await ApiCall.wbso.deleteApplicationById(id);

      messageApi.open({
        type: "success",
        content: "Application deleted!",
      });
      refetch();
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Delete failed",
      });
    } finally {
      setIsDeletingRowId(null);
    }
  };

  const handleStartWbsoApplication = async () => {
    try {
      const { data } = await ApiCall.wbso.createApplication(
        authUser?.company?.uuid
      );
      setCurrentUuid(data.uuid);
      setIsModalVisible(true);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Failed to create application",
      });
    }
  };

  const handleModalOk = async () => {
    try {
      setUploading(true);
      const values = await form.validateFields();
      const file = values.projectFile?.file;

      if (file) {
        const response = await ApiCall.wbso.uploadFile(currentUuid, file);
        // Handle file upload (e.g., send to server or process client-side)
        // Example: await ApiCall.wbso.uploadProjectFile(currentUuid, file);
        if (response.status === 201) {
          messageApi.open({
            type: "success",
            content: "File uploaded successfully!",
          });
        } else {
          messageApi.open({
            type: "error",
            content: "File upload failed!",
          });
          return;
        }
      }

      // Proceed to input fields
    } catch (error) {
      // Handle validation errors or upload failures
      console.error(error);
    } finally {
      setUploading(false);
      setIsModalVisible(false);
      router.replace(`${ALL_PAGES.input.replace(":uuid", currentUuid)}`);
    }
  };

  const handleModalCancel = () => {
    // Proceed without uploading a file
    if (currentUuid) {
      router.replace(`${ALL_PAGES.input.replace(":uuid", currentUuid)}`);
    }
    setIsModalVisible(false);
  };

  const modifiedColumns = COLUMNS?.map((c, index) => {
    if (c.dataIndex === "type") {
      return {
        ...c,
        render: (value) => {
          return "WBSO";
        },
      };
    }
    if (c.dataIndex === "status") {
      return {
        ...c,
        render: (value) => {
          const status = statusMapping[value];
          return (
            <Tooltip title={status.tooltip}>
              <Tag color="purple">{status.label}</Tag>
            </Tooltip>
          );
        },
      };
    }
    if (c.dataIndex === "value") {
      return {
        ...c,
        render: (value) => {
          const formattedValue = Number(value).toLocaleString("de-DE");
          return `${formattedValue} €`;
        },
      };
    }
    if (c.dataIndex === "hours") {
      return {
        ...c,
        render: (value) => {
          return `${value ?? 0} h`;
        },
      };
    }
    if (c.dataIndex === "action") {
      return {
        ...c,
        render: (value, rec) => {
          return (
            <div className="flex items-center gap-x-2">
              {rec.has_generated_application && (
                <Button
                  onClick={() =>
                    router.replace(
                      `${ALL_PAGES.view.replace(":uuid", rec.uuid)}`
                    )
                  }
                  variant={"outlined"}
                  icon={<EyeOutlined />}
                />
              )}
              <Button
                onClick={() =>
                  router.replace(
                    `${ALL_PAGES.input.replace(":uuid", rec.uuid)}`
                  )
                }
                variant={"outlined"}
                icon={<EditOutlined />}
              />
              <Button
                danger
                onClick={() => handleDelete(rec.uuid)}
                variant={"outlined"}
                loading={isDeletingRowId === rec.uuid}
                icon={<DeleteOutlined />}
              />
            </div>
          );
        },
      };
    }

    return c;
  });

  const fixData = data?.sort((a, b) => {
    if (dayjs(a.created_at).isBefore(dayjs(b.created_at))) {
      return 1;
    } else if (dayjs(a.created_at).isAfter(dayjs(b.created_at))) {
      return -1;
    } else {
      return 0;
    }
  });

  return (
    <LayoutLoginArea pageTitle="Applications Dashboard" shouldAdvisor={false}>
      <div className="flex flex-col w-full gap-y-8">
        {contextHolder}
        <div className="max-w-[1280px] flex items-center justify-end">
          <DropdownButtonApplication
            handleStartWbsoApplication={handleStartWbsoApplication}
          />
        </div>
        <div className="max-w-[1280px]">
          <CustomTable
            columns={modifiedColumns}
            dataSource={fixData}
            bordered
            pagination={false}
            loading={isLoading}
          />
        </div>
      </div>

      <Modal
        title="Upload WBSO Project File (Optional)"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        confirmLoading={uploading}
        okText="Upload File"
        cancelText="Skip"
      >
        <p>
          Most companies find that uploading a file, such as a project
          description or a previous application, can{" "}
          <strong>speed up the process by up to 20 times</strong>! While this
          step is optional, filling out the forms manually typically takes
          around 30 minutes. Consider uploading a document to make your
          application process quicker and easier.
        </p>
        <br />
        <Form form={form} layout="vertical">
          <Form.Item
            name="projectFile"
            label="Project File"
            valuePropName="file"
            getValueFromEvent={(e) => e}
          >
            <Upload beforeUpload={() => false} maxCount={1} accept=".pdf">
              <Button>Click to select a .pdf file</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </LayoutLoginArea>
  );
};

export default DashboardView;
