import { useQuery } from "react-query";
import { ApiCall } from "../../services";

function useWbsoApplications(props) {
  const options = props?.options || {};

  const queryKey = ["get-wbso-applications", props];
  const query = useQuery(queryKey, () => ApiCall.wbso.getApplications(), {
    ...options,
    staleTime: 1000 * 60 * 5,
  });

  return {
    ...query,
    data: query?.data?.data ?? [],
  };
}

export default useWbsoApplications;
