import { Button } from "antd";

export default function OutlinedButton({ children, ...props }) {
  return (
    <Button
      className="bg-gray border-[1px] border-[#0d393a] rounded-lg"
      {...props}
    >
      {children}
    </Button>
  );
}
